import "../../css/inputs.scss"

export default function Input({
    wholeName,
    name,
    type,
    getValue,//function that know the value
    handler,
    placeholder,
    errorMessage,
                              }){
    return (

        <div className="col-md-12">
            <div className="form-group row">
                <label className="col-md-4" htmlFor="">{wholeName}</label>
                <div className="col-md-4">
                    <div className="">
                        <input placeholder={placeholder || wholeName }
                               type={type} data-name={name}
                               onChange={handler}
                               value={getValue(name)} className="form-control" />
                    </div>
                </div>
                <div className="col-md-4">
                    {errorMessage && <span className="block validator-msg">{errorMessage}</span> }
                </div>
            </div>

        </div>
    )


}
