import {useState} from "react";
import icon from "../../images/tooltipIcon.png";

export default function InputTrueFalseText({
    subinfo,
    wholeName,
    name,
    handler,
    getValue,
    helper,
    modal
                                           }){



    const [activated, activate] = useState(getValue(name) === "true" || getValue(name) === true )//workaround for true that is saved in

    function onChange(){
        activate(!activated)
        handler({target:
            {value: !activated,
            getAttribute: function(val){
                if(val == "data-name"){
                    return name;
                }
            }}}
        )
    }

    return(
        <div className="row">
            <div className="imgRadio col-xs-12" onClick={onChange}>
                <div className={`tabWrapper ${activated && "active"}`}>
                        <div className="tab1">
                            <div className="row horiz graybg">
                                <div className="col-xs-8 col-md-4 tithead">
                                    <i className="fas fa-check"/>
                                    <span>
                                    {wholeName}
                                    
                                    {helper? <img src={icon} className="tooltipIcon inline" onClick={(e) => {
                                            e.preventDefault();
                                            modal({visible: true, content: helper})
                                        }}/>: ""}
                                    </span>
                                    {/*<img src="/img/tooltipIcon.png" className="tooltipIcon"*/}
                                    {/*                            data-toggle="modal"*/}
                                    {/*                            data-target="#helpPripojisteniCovidPlus">*/}
                                </div>
                                <div className="col-xs-12 subinfo">
                                    <p>{subinfo}</p>
                                </div>
                                {/*<div className="col-xs-12 collapse">*/}
                                {/*    <div className="params">*/}
                                {/*        <div>Test message</div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>

                        </div>
                </div>
            </div>

        </div>


    )
}
