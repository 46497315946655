import FormBox from "../Components/FormBox";
import Input from "../Components/Inputs/Input";
import InputDatePicker from "../Components/Inputs/InputDatePicker";
import InputSelect from "../Components/Inputs/InputSelect";
import InputRadio from "../Components/Inputs/InputRadio";
import {useState} from "react";
import moment from "moment";
import InputCheckbox from "../Components/Inputs/InputCheckbox";

const HafanPageThree = (props) =>{

    const [cz_citizen, setCz_citizen] = useState(true)


    const [docsOpen, setDocsOpen] = useState(false);
    function toggleDocs(event){
        event.preventDefault();
        setDocsOpen(!docsOpen)
    }

    return(

        <div>
            <form onSubmit={props.handleSubmit}>
                {/*<Recap/>*/}

                <FormBox>
                    <div className="row">
                        <h3>Informace o Vás</h3>
                        {/*<InputRadio*/}
                        {/*    options={[{value: true, text: "Ano"}, {value: false, text: "Ne"}]}*/}
                        {/*    wholeName={"Občan ČR"}*/}
                        {/*    name={"cz_citizen"}*/}
                        {/*    handler={setCz_citizen}*/}
                        {/*    defaultValue={cz_citizen}*/}
                        {/*/>*/}
                        <Input type="text" name="own_title_before" wholeName='Titul' handler={props.handleChange} getValue={props.getValue}/>
                        <Input type="text" name="own_firstname" wholeName='Jméno' handler={props.handleChange} getValue={props.getValue}/>
                        <Input type="text" name="own_surname" wholeName='Příjmení' handler={props.handleChange} getValue={props.getValue}/>


                        {(cz_citizen === true ?
                                <Input type="text" name="own_birth_num" wholeName='Rodné číslo' handler={props.handleChange} getValue={props.getValue}/> : ""
                                // <InputDatePicker wholeName="Datum Narození" name="person_birthDate" handler={props.handleChange}/>
                        )}

                        <Input type="email" name="own_email" wholeName='Email' handler={props.handleChange} getValue={props.getValue}/>
                        <Input type="tel" name="own_phone" wholeName='Telefon' handler={props.handleChange} getValue={props.getValue}/>
                    </div>
                </FormBox>

                <FormBox>
                <div className={"row"}>
                        <h3>Vaše adresa</h3>

                        <div className={"form-group"}>
                            <h4>Trvalá adresa</h4>
                            <Input type="text" name="addr_street" wholeName='Ulice' handler={props.handleChange} required getValue={props.getValue}/>
                            <Input type="text" name="addr_house_num" wholeName='Číslo popisné' handler={props.handleChange} required getValue={props.getValue}/>
                            <Input type="text" name="addr_town" wholeName='Město' handler={props.handleChange} required getValue={props.getValue}/>
                            <Input type="text" name="addr_post_code" wholeName='PSČ' handler={props.handleChange} required getValue={props.getValue}/>
                        </div>

                        <div className={"form-group"}>
                            <InputRadio
                            options={[{value: false, text: "Ano"}, {value: true, text: "Ne"}]}
                            wholeName={"Kontaktní adresa stejná jako trvalá"}
                            handler={props.handleChange}
                            defaultValue={props.contactAddress.different}
                            name="caddr_different"
                            getValue={props.getValue}
                            />
                        </div>

                        {props.contactAddress.different === true &&
                            <div className={"form-group"}>
                                <h4>Kontakntní adresa</h4>
                                <Input type="text" name="caddr_street" wholeName='Ulice' handler={props.handleChange} required getValue={props.getValue}/>
                                <Input type="text" name="caddr_house_num" wholeName='Číslo popisné' handler={props.handleChange} required getValue={props.getValue}/>
                                <Input type="text" name="caddr_town" wholeName='Město' handler={props.handleChange} required getValue={props.getValue}/>
                                <Input type="text" name="caddr_post_code" wholeName='PSČ' handler={props.handleChange} required getValue={props.getValue}/>
                            </div>
                        }

                        <div className={"form-group"}>
                            <InputRadio
                                options={[{value: false, text: "Ano"}, {value: true, text: "Ne"}]}
                                wholeName={"Adresa chovu je stejná jako trvalá"}
                                handler={props.handleChange}
                                defaultValue={props.breedingAddress.different}
                                name="baddr_different"
                                getValue={props.getValue}
                            />
                        </div>

                        {props.breedingAddress.different === true &&
                            <div className={"form-group"}>
                                <h4>Adresa chovu</h4>
                                <Input type="text" name="baddr_street" wholeName='Ulice' handler={props.handleChange} getValue={props.getValue}/>
                                <Input type="text" name="baddr_house_num" wholeName='Číslo popisné' handler={props.handleChange} getValue={props.getValue}/>
                                <Input type="text" name="baddr_town" wholeName='Město' handler={props.handleChange} getValue={props.getValue}/>
                                <Input type="text" name="baddr_post_code" wholeName='PSČ' handler={props.handleChange} getValue={props.getValue}/>
                            </div>
                        }
                    </div>
                </FormBox>

                <FormBox>
                    <div className={"row"}>
                        <h3>Dodatečné informace</h3>
                        <Input type="text" name="dog_chip_num" wholeName='Číslo čipu' handler={props.handleChange} getValue={props.getValue}/>
                        <InputDatePicker
                            wholeName="Datum počátku pojištění"
                            name="ins_ins_start"
                            defaultValue={moment().add(1,'days')}
                            disabled
                            getValue={props.getValue}
                            helper="ins_ins_start" 
                            modal={props.setModalProps}
                        />
                        <InputSelect
                            wholeName={"Frekvence platby"}
                            options={[
                                {value: "ROCNI", label: "Ročně"},
                                {value: "POLOLETNI", label: "Pololetně"},
                                {value: "CTVRTLETNI", label: "Čtvrtletně"},
                            ]}
                            name="ins_payment_freq"
                            handler={props.handleChange}
                            defaultValue="ROCNI"
                            getValue={props.getValue}
                        />
                    </div>
                </FormBox>

                <FormBox addClass={"graybg"}>
                    <div className={"row"}>
                        <h3 className="greytext">Potvrzení souhlasů</h3>
                        <div className="row mb-4">
                            <div className="col-xs-12">
                                <InputCheckbox getValue={props.getValue}
                                               handler={props.handleChange}
                                               name="ins_electronic_comm"
                                               text={<>Souhlasím s elektronickou <a onClick={(e) => {e.preventDefault();props.setModalProps({visible: true, content:"ins_electronic_comm"})}}>komunikací</a></>}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-xs-12">
                                <InputCheckbox getValue={props.getValue}
                                               handler={props.handleChange}
                                               name="ins_not_direct_marketing"
                                               text={<>Nesouhlasím se zasíláním obchodních <a onClick={(e) => {e.preventDefault();props.setModalProps({visible: true, content:"ins_not_direct_marketing"})}}>sdělení a nabídek služeb České podnikatelské pojišťovny</a></>}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className={"form-group"}>
                                <InputRadio
                                    options={[{value: true, text: "Ano, mám zájem"}, {value: false, text: "Ne, nemám zájem"}]}
                                    wholeName={<>Zasílání nabídek <a onClick={(e) => {e.preventDefault();props.setModalProps({visible: true, content:"ins_other_marketing"})}}>obchodních partnerů České podnikatelské pojišťovny</a></>}
                                    handler={props.handleChange}
                                    // defaultValue={props.getValue(marketing)}
                                    name="ins_other_marketing"
                                    getValue={props.getValue}
                                />
                            </div>
                        </div>
                    </div>
                </FormBox>

                <div class="formBox graybg souhrn" id="souhlasy">

                    <div class="collapsediv">
                        <a href="#docs" data-toggle="collapse" className={docsOpen ? 'ah3': 'ah3 collapsed'}  onClick={toggleDocs}>
                            <h3 class="rollable greytext">Důležité dokumenty</h3>
                        </a>

                        <div className={docsOpen ? 'formBoxContent collapse in': 'formBoxContent collapse'} id="docs">
                            <div class="collapsecontent">
                                <div>
                                    <div class="filebox">
                                        <p class="pdficon"><a target="_blank" href="documents/VPPM.pdf">Všeobecné pojistné podmínky</a></p>
                                    </div>
                                </div>
                                <div>
                                    <div class="filebox">
                                        <p class="pdficon"><a target="_blank" href="documents/IpZoP.pdf">Informace pro zájemce o pojištění</a></p>
                                    </div>
                                </div>
                                <div>
                                    <div class="filebox">
                                        <p class="pdficon"><a target="_blank" href="documents/IPID-1.pdf">Informační dokument o pojistném produktu</a></p>
                                    </div>
                                </div>
                                <div>
                                    <div class="filebox">
                                        <p class="pdficon"><a target="_blank" href="documents/Informacni_memorandum.pdf">Informace o zpracování osobních údajů</a></p>
                                    </div>
                                </div>
                                <div>
                                    <div class="filebox">
                                        <p class="pdficon"><a target="_blank" href="documents/DPPLVP.pdf">Doplňkové pojistné podmínky</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row stepButtons">
                    <div className="col-lg-6  col-sm-12">
                        <button type="button" className="btn btn-secondary backButton" onClick={() => props.setStep(2)}>
                            Zpět
                        </button>
                    </div>
                    <div className="col-lg-6 col-sm-12  text-right">
                        <button className={"btn btn-primary continueButton"}>
                            Pokračovat
                        </button>
                    </div>
                </div>

            </form>
        </div>

    )
}

export default HafanPageThree;
