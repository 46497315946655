
import Hafan from "./Pages/Hafan";

function App() {
  return (
   <Hafan/>
  );
}

export default App;
