import Select from "antd/lib/select";
import icon from "../../images/tooltipIcon.png";

export default function InputSelect({
    options,
    wholeName,
    errorMessage,
    name,
    handler,
    defaultValue,
    getValue,
    helper,
    modal
}){


    function onChange(val) {
        let object = {target:
            {value: val,
            getAttribute: function(val){
                if(val == "data-name"){
                    return name;
                }
            }}}
        handler(object)
    }

    return(
    <div className="col-md-12">
        <div className="form-group row">
            <label className="col-md-4" htmlFor="">{wholeName}
                {helper? <img src={icon} className="tooltipIcon inline" onClick={(e) => {
                    e.preventDefault();
                    modal({visible: true, content: helper})
                }}/>: ""}
            </label>
            <div className="col-md-4">
                <Select options={options}
                        style={{ width: '100%'}}
                        size={"large"}
                        placeholder={wholeName}
                        onChange={onChange}
                        defaultValue={defaultValue}
                        value={!!getValue(name) ? getValue(name) : undefined}
                />
            </div>
            <div className="col-md-4">
                {errorMessage && <span className="block validator-msg">{errorMessage}</span> }
            </div>
        </div>
    </div>

    )
}
