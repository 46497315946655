import FormBox from "../Components/FormBox";
import {sexShort} from "../helpers/functions";

                    const Recap = ({
                    dog, 
                    insurance
                }) => {


                    return(
                    <FormBox>
                    <div className={"row"}>
                    <h4>Shrnutí požadavku</h4>
                    <div className={"col-md-8"}>
                    <div>{dog.name}({sexShort(dog.sex)}) - {dog.breed}, Narozen {dog.birth_date}</div>
                    <div>Pojistná částka: {dog.amount} Kč</div>
                    <div>Spoluúčast: {insurance.participation} Kč</div>
                    {dog.working_dog? <div>Pracovní pes</div> : ""}
                    {dog.racing_dog? <div>Speciální pes</div> : ""}
                    </div>
                    <div className={"col-md-4"}>
                    </div>
                    <div className={"col-md-6"}>
                    <div></div>
                </div>

            </div>
        </FormBox>
    )
}
export default Recap;
