import FormBox from "../Components/FormBox";
import {useState} from "react";

const HafanPageFour = (props) =>{

    console.log(props.response)

    let payment_freq = "Ročně"
    if(props.response.insurance.payment_freq == "POLOLETNI"){
        payment_freq = "Pololetně"
    }else if(props.response.insurance.payment_freq == "CTVRTLETNI"){
        payment_freq = "Čtvrtletně"
    }

    return(
        <div>
            <form onSubmit={props.handleSubmit}>
                <FormBox>
                    <div className={"row"}>
                        <h4>Rekapitulace</h4>
                        <div className={"col-md-8"}>
                            <div>Mazlíček: {props.response.dog.name} - {props.response.dog.breed}, Narozen: {props.response.dog.birth_date}  
                            {props.doggo.working_dog? ", Pracovní pes": ""}
                            {props.doggo.racing_dog? ", Speciální pes": ""}</div>
                            <div>Pojistná částka: {props.doggo.amount} Kč, Spoluúčast: {props.insurance.participation} Kč</div>
                            <div>Pojistník: {props.response.owner.firstname} {props.response.owner.surname}, Rodné číslo: {props.response.owner.birth_num}</div>
                        </div>
                        <div className={"col-md-4"}>
                        </div>
                        <div className={"col-md-6"}>
                            <div>Trvalá adresa: {props.response.owner.address.street} {props.response.owner.address.house_num}, {props.response.owner.address.town} {props.response.owner.address.post_code}</div>
                            <div>Kontaktní adresa: {props.response.owner.contact_address.street} {props.response.owner.contact_address.house_num}, {props.response.owner.contact_address.town} {props.response.owner.contact_address.post_code}</div>
                            <div>Adresa chovu: {props.response.owner.breeding_address.street} {props.response.owner.breeding_address.house_num}, {props.response.owner.breeding_address.town} {props.response.owner.breeding_address.post_code}</div>
                        </div>
                    </div>
                </FormBox>

                <div className="formBox graybg">
                    <div className="formBoxContent summary">
                        <div className={"row"}>
                            <div className={"col-xs-8"}>
                                <h3>Vaše cena</h3>
                            </div>
                            <div className="col-xs-4 cena">
                                <h3>
                                    <span>{props.response.insurance.payment}</span>
                                    <span className="kc"> Kč</span>
                                </h3>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-xs-8"}>
                                <h3>Platby</h3>
                            </div>
                            <div className="col-xs-4 cena">
                                <h3>
                                    <span>{
                                        payment_freq
                                    }</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row stepButtons">
                    <div className="col-lg-6  col-sm-12">
                        <button type="button" className="btn btn-secondary backButton" onClick={() => props.setStep(3)}>
                            Zpět
                        </button>
                    </div>

                    <div className="col-lg-6 col-sm-12  text-right">
                        <button className={"btn btn-primary continueButton"}>
                            Sjednat
                        </button>
                    </div>
                </div>

            </form>
        </div>

    )
}

export default HafanPageFour;
