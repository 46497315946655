import Radio from 'antd/lib/radio';
import {useState} from "react";

export default function InputRadio({
    options,
    wholeName,
    errorMessage,
    handler,
    defaultValue,
    name

                                   }){

    const [value, setValue] = useState(defaultValue)
    function onChange(e){
        setValue(e.target.value);
        handler({target:
            {value: e.target.value,
            getAttribute: function(val){
                if(val == "data-name"){
                    return name;
                }
            }}}
        )
    }
    return(
        <div className="col-md-12">
            <div className="form-group row">
                <label className="col-md-4" htmlFor="">{wholeName}</label>
                <div className="col-md-4">
                    <Radio.Group onChange={onChange} value={value} defaultValue={defaultValue}>
                        {options.map((opt) =>
                            <Radio
                                key={opt.value}
                                value={opt.value}
                            >
                                {opt.text}</Radio>
                        )}
                    </Radio.Group>
                </div>
                <div className="col-md-4">
                    {errorMessage && <span className="block validator-msg">{errorMessage}</span> }
                </div>
            </div>
        </div>


    )
}
