import Checkbox from "antd/lib/checkbox";
import {useState} from "react";

const InputCheckbox = ({
    text,
    getValue,
    handler,
    name
                       }) => {

    const [checked, setChecked] = useState(getValue(name) === "true" || getValue(name) === true)

    function onChange() {
        setChecked(!checked);
        handler({target:
                {value: !checked,
                    getAttribute: function(val){
                        if(val == "data-name"){
                            return name;
                        }
                    }}}
        )
    }

    return(
        <Checkbox
            onChange={onChange}
            checked={checked}
        >
            {text}
        </Checkbox>
    )
}

export default InputCheckbox
