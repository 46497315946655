
const Navigation = ({step}) => {
    return(
        <div className="navigation">
            <nav className="container">


                <div>
                    <div className="hidden-xs">
                        <div className="navbgline"/>
                        <ul className="nav col-sm-12">
                            <li>
                                <a
                                    href="#"
                                   className={`${step >= 1 ? "active":""} ${step === 1 ? "current":""}`}
                                    disabled={step < 1 ? true: false}
                                >
                                    Základní údaje
                                </a>
                            </li>

                            <li>
                                <a
                                    href="#"
                                    className={`${step >= 2 ? "active":""}  ${step === 2 ? "current":""}  `}
                                    disabled={step < 2 ? true: false}
                                >
                                    Kalkulace
                                </a>
                            </li>

                            <li>
                                <a   href="#"
                                     className={`${step >= 3 ? "active":""} ${step === 3 ? "current":""}`}
                                     disabled={step < 3 ? true: false}>
                                    Doplnění údajů
                                </a>
                            </li>

                            <li>
                                <a href="#"
                                   className={`${step >= 4 ? "active":""} ${step === 4 ? "current":""}`}
                                   disabled={step < 4 ? true: false}>
                                    Shrnutí</a>
                            </li>

                            <li>
                                <a href="#"
                                   className={`${step >= 5 ? "active":""} ${step === 5 ? "current":""}`}
                                   disabled={step < 5 ? true: false}>Platba</a>
                            </li>
                        </ul>
                    </div>

                    {/*For Mobile devices*/}
                    <div className="visible-xs">
                        <div className="row nav">
                            <div className="col-xs-6">
                                <span>
                                    {step === 1 && "Základní údaje"}
                                    {step === 2 && "Kalkulace"}
                                    {step === 3 && "Doplnění údajů"}
                                    {step === 4 && "Shrnutí"}
                                    {step === 5 && "Platba"}
                                </span>
                            </div>
                            <div className="col-xs-6 text-right">
                                <span>{step}</span> / 5 kroků
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navigation;
