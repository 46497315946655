import { Modal } from 'antd';

export default function HelperModal(props){


    const handleOk = () => {
        props.setModalProps({visible:false})
    };

    const handleCancel = () => {
        props.setModalProps({visible:false})
    };

    function generateText(content){
        switch(content){
            case "ins_electronic_comm":
                    return "Souhlasíte s tím, abyste v záležitostech pojistného vztahu byl kontaktován prostředky elektronické komunikace (například mobilní telefon, e-mail a další).\n" +
                        "\n" +
                        "Pokud nevyslovíte souhlas s elektronickou komunikací, nelze dokončit sjednání pojištění. Pro sjednání pojištění nás kontaktujte telefonicky nebo emailem, případně zanechte kontakt a my se vám ozveme."
            case "ins_not_direct_marketing":
                return "Zpracování osobních údajů pro účely přímého marketingu\n" +
                    "\n" +
                    "Jako pojistník berete na vědomí, že Vaše identifikační a kontaktní údaje a údaje o využívání služeb může pojistitel také zpracovávat na základě jeho oprávněného zájmu pro účely zasílání svých reklamních sdělení a nabízení svých služeb; nabídku od pojistitele můžete dostat elektronicky (zejména SMSkou, e-mailem, přes sociální sítě nebo telefonicky) nebo klasickým dopisem či osobně od zaměstnanců pojistitele.\n" +
                    "\n" +
                    "Proti takovému zpracování máte právo kdykoli podat námitku. Pokud si nepřejete, aby Vás pojistitel oslovoval s jakýmikoli nabídkami, zaškrtněte prosím toto pole."
            case "ins_other_marketing":
                return "Pojistitel bude s Vaším souhlasem zpracovávat Vaše identifikační a kontaktní údaje, údaje pro vyhodnocení potřeb a posouzení vhodnosti pojištění, údaje pro ocenění rizika při vstupu do pojištění a údaje o využívání služeb, a to pro účely:\n" +
                    "\n" +
                    "zasílání slev či jiných nabídek třetích stran, a to i elektronickými prostředky, a\n" +
                    "zpracování Vašich osobních údajů nad rámec oprávněného zájmu pojistitele za účelem vyhodnocení Vašich potřeb a zasílání relevantnějších nabídek (jedná se o některé případy sledování Vašeho chování, spojování osobních údajů shromážděných pro odlišné účely, použití pokročilých analytických technik).\n" +
                    "Tento souhlas je dobrovolný, platí po dobu neurčitou, můžete jej však kdykoliv odvolat. V případě, že souhlas neudělíte nebo jej odvoláte, nebudou Vám zasílány nabídky třetích stran a některé nabídky pojistitele nebude možné plně přizpůsobit Vašim potřebám. Máte také právo kdykoliv požadovat přístup ke svým osobním údajům."
            case "dog_birth_date":
                return "Sjednat pojištění můžete od 3 měsíců do 8 let…čím dříve, tím budete platit méně"
            case "ins_ins_start":
                return "Chcete-li jiný datum počátku pojištění kontaktujte nás emailem nebo telefonicky"
            case "dog_amount":
                return "Pojistná částka je horní hranicí pojistného plnění pojistitele pro jednu a všechny pojistné události nastalé v průběhu jednoho pojistného roku.\nJe to maximální částka, kterou za vás zaplatí pojišťovna za 1 rok."
            case "ins_participation":
                return "Pojištění se sjednává se spoluúčastí pojištěného na každé pojistné události částkou sjednanou v pojistné smlouvě."
            case "dog_racing_dog":
                return "Zaškrtněte, pokud potřebujete, aby se smlouva vztahovala i na události při soutěžích, výstavách nebo závodech"
            case "dog_working_dog":
                return <div>Zaškrtněte, pokud s hafanem provozujete některou z definovaných aktivit. My Vám za odměnu dáme <b><u>slevu na pojištění</u></b></div>
            default:
                return "loading"
        }
    }

    return(
        <Modal title="Nápověda" visible={props.isShown} onOk={handleOk} onCancel={handleCancel}>
            <>{generateText(props.content)}</>
        </Modal>
    )
}
