import Header from "../Layouts/Elements/Header";
import Navigation from "../Components/Navigation";


export default function HafanInsurance({ step, children }) {
    return (

        <>
            <Header/>
            <Navigation step={step}/>
            <main>
                <div className="container">
                {children}
                </div>
            </main>
            {/*<Footer/>*/}
        </>





    );
}
