import FormBox from "../Components/FormBox";
import Input from "../Components/Inputs/Input";
import InputDatePicker from "../Components/Inputs/InputDatePicker";
import RadioSelect from "../Components/Inputs/RadioSelect";
import InputSelect from "../Components/Inputs/InputSelect";
import InputTrueFalseText from "../Components/Inputs/InputTrueFalseText";

const HafanPageOne = (props) =>{

    return(

        <div>
            <form >
                <FormBox>
                    <div className="row">Před sjednáním pojištění si nezapomeňte přečíst <a target="_blank" href="documents/IPID-1.pdf">Informační dokument o pojistném produktu</a> a  <a target="_blank" href="documents/IpZoP.pdf">Informace pro zájemce o pojištění</a>.</div>
                </FormBox>
                <FormBox>
                     <div className="row">
                        <h3>Informace o vašem mazlíčkovi</h3>
                        <Input type="text" name="dog_name" wholeName='Jméno psa' handler={props.handleChange} getValue={props.getValue}/>
                        <Input type="text" name="dog_breed" wholeName='Plemeno' handler={props.handleChange} getValue={props.getValue}/>
                        <InputDatePicker wholeName="Datum narození" name="dog_birth_date" handler={props.handleChange} getValue={props.getValue} helper="dog_birth_date" modal={props.setModalProps}/>
                        <RadioSelect wholeName="Pohlaví psa"
                            items={[
                                {wholeName: "Fena", name: "FENA",icon:"fa-solid fa-venus" },
                                {wholeName: "Pes", name: "PES", icon:"fa-solid fa-mars"},
                            ]}
                            name="dog_sex"
                            handler={props.handleChange}
                             getValue={props.getValue}
                        >
                        </RadioSelect>
                    </div>
                </FormBox>

                <FormBox>

                    <div className="row">
                        <h3>Informace o pojištění</h3>
                        <InputSelect
                            wholeName={"Pojistná částka"}
                            options={[
                                {value: 10000, label: "10'000 Kč"},
                                {value: 20000, label: "20'000 Kč"},
                                {value: 30000, label: "30'000 Kč"},
                                {value: 40000, label: "40'000 Kč"},
                                {value: 50000, label: "50'000 Kč"}
                            ]}
                            name="dog_amount"
                            handler={props.handleChange}
                            getValue={props.getValue}
                            helper="dog_amount" 
                            modal={props.setModalProps}
                            />
                            <RadioSelect wholeName="Spoluúčast 10%, minimálně"
                                     items={[
                                         {wholeName: "1 000 Kč", name: 1000},
                                         {wholeName: "2 000 Kč", name: 2000},
                                     ]}
                                     name="ins_participation"
                                     handler={props.handleChange}
                                     getValue={props.getValue}
                                     helper="ins_participation" 
                                     modal={props.setModalProps}
                        >
                        </RadioSelect>
                        <RadioSelect wholeName="Pojistit pro oblast"
                                     items={[
                                         {wholeName: "Evropa", name: "EVROPA"},
                                         {wholeName: "Česko", name: "CESKA_REPUBLIKA"},
                                     ]}
                                     name="dog_country"
                                     handler={props.handleChange}
                                     getValue={props.getValue}
                        >
                        </RadioSelect>
                    </div>
                </FormBox>
                <FormBox>
                    <div className={"row"}>
                        <h3>Vyberte vhodná připojištění (nepovinné)</h3>
                        <InputTrueFalseText
                            subinfo={"Asistenční pes, canisterapie, Záchranná brigáda kynologů, Horská služba"}
                            wholeName={"Pracovní pes"}
                            name={"dog_working_dog"}
                            handler={props.handleChange}
                            getValue={props.getValue}
                            helper="dog_working_dog"
                            modal={props.setModalProps}
                        />
                        <InputTrueFalseText
                            subinfo={"Soutěže, výstavy, závody"}
                            wholeName={"Speciální pes"}
                            name={"dog_racing_dog"}
                            handler={props.handleChange}
                            getValue={props.getValue}
                            helper="dog_racing_dog"
                            modal={props.setModalProps}
                        />
                    </div>


                </FormBox>


                <div className="row stepButtons">
                    <div className="col-lg-6 col-sm-12 col-lg-push-6 text-right">
                        <button className={"btn btn-primary continueButton"} onClick={props.handleSubmit}>
                            Pokračovat
                        </button>
                    </div>
                </div>

            </form>
        </div>

    )
}

export default HafanPageOne;
