

export default function FormBox(props) {
    return(
        <div className={`formBox ${props.addClass}`}>
            <div className="formBoxContent">
                {props.children}
            </div>
        </div>
    )
}
