import React from "react";
import logo from '../../images/logo.png';

const Header = () => {
    return(

        <header className="container">
            <div className="sticky-header__row">
                <div className="cta__container">
                    <div className="cta__box cta__box--phone">
                        {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.14 30.875" width="16.14" height="30.875"*/}
                        {/*     className="phone" id="Phone-6f73060f--sprite">*/}
                        {/*    <g data-name="Group 571">*/}
                        {/*        <path data-name="Path 156"*/}
                        {/*              d="M16.139 15.428v13.223a2.124 2.124 0 01-2.147 2.224H2.156A2.109 2.109 0 010 28.695V2.268A2.161 2.161 0 011.053.282 2.079 2.079 0 012.165.003h11.816a2.054 2.054 0 012.1 1.635 2.888 2.888 0 01.055.626q.005 6.582.003 13.164zM14.021 25.95V3.518H2.129V25.95zM8.083 1.4H5.626a.358.358 0 00-.348.335.323.323 0 00.3.351.98.98 0 00.195.015h4.624a.471.471 0 00.247-.038c.089-.06.2-.158.21-.248a.491.491 0 00-.135-.334.44.44 0 00-.29-.079c-.786-.005-1.566-.003-2.347-.003zm0 25.97a1.032 1.032 0 00-1.053 1.037 1.048 1.048 0 101.053-1.037z"*/}
                        {/*              fill="#8dbcef"></path>*/}
                        {/*    </g>*/}
                        {/*</svg>*/}
                        {/*<a href="tel:957444555">957 444 555</a>*/}
                    </div>
                    <div className="cta__container--links">

                        {/*<a className="cta__box cta__box--mycpp" target="_blank" href="https://www.mojecpp.cz/">*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.876 26.875" width="26.876"*/}
                        {/*         height="26.875" className="avatar" id="Avatar-0ab2ae7a--sprite">*/}
                        {/*        <g data-name="Group 572" fill="#fff">*/}
                        {/*            <path data-name="Path 157"*/}
                        {/*                  d="M13.43 26.875a13.437 13.437 0 1113.445-13.426A13.426 13.426 0 0113.43 26.875zm.011-24.968a11.531 11.531 0 1011.527 11.549A11.5 11.5 0 0013.444 1.907z"></path>*/}
                        {/*            <path data-name="Path 158"*/}
                        {/*                  d="M20.482 20.488a10.1 10.1 0 01-14.092 0 7.857 7.857 0 012.128-2.577 7.7 7.7 0 014.12-1.66 7.892 7.892 0 017.844 4.237z"></path>*/}
                        {/*            <path data-name="Path 159"*/}
                        {/*                  d="M17.346 10.781a3.906 3.906 0 11-7.809-.028 3.905 3.905 0 017.809.028z"></path>*/}
                        {/*        </g>*/}
                        {/*    </svg>*/}
                        {/*    Moje ČPP: Klientská zóna*/}
                        {/*</a>*/}
                        {/*<a className="cta__box cta__box--report" target="_blank"*/}
                        {/*   href="https://www.cpp.cz/klientsky-servis/reseni-skody">*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.932 24.135" width="15.932"*/}
                        {/*         height="24.135" className="lightning" id="Lightning-52851016--sprite">*/}
                        {/*        <g data-name="Group 573">*/}
                        {/*            <path data-name="Path 161"*/}
                        {/*                  d="M10.826 8.713l.723.385 4.025 2.14a.542.542 0 01.273.793.773.773 0 01-.187.2q-2.837 2.272-5.678 4.54L1.63 23.449l-.65.52c-.02.016-.04.033-.061.047a.66.66 0 01-.788-.026.572.572 0 010-.737c.207-.307.423-.607.635-.91l6.9-9.86.052-.075c-.055-.032-.106-.062-.158-.09L2.774 9.771a.868.868 0 01-.268-.2.533.533 0 01.126-.8c.326-.25.66-.491.99-.735Q8.96 4.083 14.294.126a.573.573 0 01.877.164.594.594 0 01-.049.572q-.638 1.161-1.274 2.324l-2.956 5.389c-.02.046-.04.088-.066.138z"*/}
                        {/*                  fill="#fff"></path>*/}
                        {/*        </g>*/}
                        {/*    </svg>*/}
                        {/*    Řešení škody*/}
                        {/*</a>*/}

                    </div>
                </div>
            </div>
            <div className="headerInner">

                <a><img src={logo} alt={"Logo"} className={"logo"}/></a>
                <h1 className="desktopH1">
                    <div>Pojištění HAFAN</div>
                </h1>
                {/*<a className="phoneNumber">957 444 555</a>*/}
            </div>
            <div className="mobileH1">
                <div>Pojištění HAFAN</div>
            </div>
        {/*    <script src="https://code.jquery.com/jquery-3.4.1.slim.min.js"*/}
        {/*            integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"*/}
        {/*            crossOrigin="anonymous"/>*/}
        {/*    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"*/}
        {/*            integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"*/}
        {/*            crossOrigin="anonymous"/>*/}
        {/*    <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/js/bootstrap.min.js"*/}
        {/*            integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"*/}
        {/*            crossOrigin="anonymous"/>*/}
        {/*    <script src="https://cdn.jsdelivr.net/npm/bootstrap@3.4.1/dist/js/bootstrap.min.js"*/}
        {/*            integrity="sha384-aJ21OjlMXNL5UyIl/XNwTMqvzeRMZH2w8c5cRVpzpU8Y5bApTppSuUkhZXN0VxHd"*/}
        {/*            crossOrigin="anonymous"/>*/}

    {/*        <script src="https://code.jquery.com/jquery-3.4.1.slim.min.js"*/}
    {/*integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"*/}
    {/*crossOrigin="anonymous"/>*/}


            <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
    integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
    crossOrigin="anonymous"/>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
    integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
    crossOrigin="anonymous"/>

            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.4.1/dist/css/bootstrap.min.css"
                  integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu"
                  crossOrigin="anonymous"/>



            {/*<link href="/resources/css/font-awesome/all.min.css" rel="stylesheet"/>*/}
            {/*<script type="text/javascript" src="https://cdn.jsdelivr.net/jquery/latest/jquery.min.js"></script>*/}
            {/*<script type="text/javascript" src="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js"/>*/}
            {/*<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js"/>*/}
            {/*<link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css" />*/}


        </header>
    )

}

export default Header;
