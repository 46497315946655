import FormBox from "../Components/FormBox";
import moment from "moment";
import {QRCode} from "react-qrcode-logo";

const HafanPageFive = ({
    amount,
    cntr_number
                       }) =>{




    return(

        <div>
            <form>
                {/*<Recap/>*/}

                <FormBox>
                    <h1>Děkujeme Vám za pojištění vašeho mazlíčka</h1>
                    <h4>Můžete zaplatit přes QR platbu níže či zadat bankovní příkaz manuálně.</h4>
                    <div className={"row text-center"}>
                        <QRCode value={`SPD*1.0*ACC:CZ9008000000000700135002*AM:${amount}*CC:CZK*X-VS:${cntr_number}*X-KS:3558*`} />
                    </div>

                    <div style={{marginBottom:"15px"}}>
                    <h3>Platební údaje</h3>
                        <div className={"row"}>
                            <div className={"col-md-3"}><h5>ČÁSTKA K ÚHRADĚ</h5></div>
                            <div className={"col-md-3"}><h5>{amount} Kč</h5></div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-3"}><h5>ČÍSLO ÚČTU</h5></div>
                            <div className={"col-md-3"}><h5>700135002/0800</h5></div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-3"}><h5>VARIABILNÍ SYMBOL</h5></div>
                            <div className={"col-md-3"}><h5>{cntr_number}</h5></div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-md-3"}><h5>DATUM SPLATNOSTI</h5></div>
                            <div className={"col-md-3"}><h5>{moment().add(15, 'days').format("DD.MM.YYYY")}</h5></div>
                        </div>
                    </div>

                    <h4>Tyto informace se neztratí! Právě jsme Vám poslali email, kde jsou mimo jiné platební pokyny v příloze.</h4>
                </FormBox>

            </form>
        </div>

    )
}

export default HafanPageFive;
