import HafanInsurance from "../Layouts/HafanInsurance";
import {useState, useEffect} from "react";
import "../css/custom-styles.css"
import '@fortawesome/fontawesome-free/css/all.min.css';
import HafanPageOne from "../Layouts/HafanPageOne";
import HafanPageTwo from "../Layouts/HafanPageTwo";
import HafanPageThree from "../Layouts/HafanPageThree";
import HafanPageFive from "../Layouts/HafanPageFive";
import HafanPageFour from "../Layouts/HafanPageFour";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { warn, error, errorMesssage } from "../helpers/functions";
import moment from "moment";
import {getUrl} from "../helpers/getUrl";
import Loader from "../Layouts/Elements/Loader";
import HelperModal from "../Components/HelperModal";

/**
 * HafanInsurance is a layout, which cares about header, ?navigation? and footer
 * in variable content is stored actual content for the certain step
 * @returns {JSX.Element}
 * @constructor
 */

const Hafan = () => {



    //console.log(getUrl())
    // const HafanPageOne =  lazy(() => import("./../Layouts/HafanPageOne.js"));
    // const HafanPageTwo =  lazy(() => import("./../Layouts/HafanPageTwo.js"));
    // const HafanPageThree =  lazy(() => import("./../Layouts/HafanPageThree.js"));
    // const HafanPageFour =  lazy(() => import("./../Layouts/HafanPageFour.js"));
    // const HafanPageFive =  lazy(() => import("./../Layouts/HafanPageFive.js"));

    const[step, setStep] = useState(1);//on load the first step is 0
    const[response, setResponse] = useState([]);//on load the first step is 0
    const[loading, setLoading] = useState(false);
    const [modalProps, setModalProps] = useState({visible: false, content:""})
    let content = "";//automatically generated according to

    const [doggo, setDoggo] = useState({
        name: "",
        amount: "",
        birth_date: "",
        working_dog: false,
        racing_dog: false,
        country: "",
        sex: "",
        chip_num: "",
        breed: ""
    });
    const [owner, setOwner] = useState({
        subject_type: "P",
        title_before: "",
        firstname: "",
        surname: "",
        title_after: "",
        birth_date: "",
        birth_num: "",
        email: "",
        phone: "",
    });
    const [address, setAddress] = useState({
        town: "",
        street: "",
        district: "",
        house_num: "",
        orient_num: "",
        post_code: "",
        country: "CZE",
    });
    const [contactAddress, setContactAddress] = useState({
        different: false,
        town: "",
        street: "",
        district: "",
        house_num: "",
        orient_num: "",
        post_code: "",
        country: "CZE",
    });
    const [breedingAddress, setBreedingAddress] = useState({
        different: false,
        town: "",
        street: "",
        district: "",
        house_num: "",
        orient_num: "",
        post_code: "",
        country: "CZE",
    });
    const [insurance, setInsurance] = useState({
        ins_start: moment().add(1,'days').format("DD.MM.YYYY"),
        payment_freq: "ROCNI",
        not_direct_marketing: false,
        other_marketing: false,
        electronic_comm: true,
        participation:""
    });
    const handleChange = (event) => {
        let value = event.target.value
        let name = event.target.getAttribute("data-name").split("_")
        let id = name.shift()
        if(id === 'dog'){
            setDoggo({ ...doggo, [name.join("_")]: value });
        }else if(id === 'own'){
            setOwner({ ...owner, [name.join("_")]: value });
        }else if(id === 'addr'){
            setAddress({ ...address, [name.join("_")]: value });
        }else if(id === 'caddr'){
            setContactAddress({ ...contactAddress, [name.join("_")]: value });
        }else if(id === 'baddr'){
            setBreedingAddress({ ...breedingAddress, [name.join("_")]: value });
        }else if(id === 'ins'){
            setInsurance({ ...insurance, [name.join("_")]: value });
        }
    };

    const getValue = (name) => {
        name = name.split("_")
        let id = name.shift()
        if(id == 'dog'){
            return doggo[name.join("_")];
        }else if(id == 'own'){
            return owner[name.join("_")];
        }else if(id == 'addr'){
            return address[name.join("_")];
        }else if(id == 'caddr'){
            return contactAddress[name.join("_")];
        }else if(id == 'baddr'){
            return breedingAddress[name.join("_")];
        }else if(id == 'ins'){
            return insurance[name.join("_")];
        }
    };

    function validateInputs(){
        if(step == 1){
            if(!(doggo.name && doggo.amount && doggo.birth_date && doggo.country && doggo.sex && doggo.breed && insurance.participation)){
                warn('Prosím vyplňte všechny údaje!')
                setLoading(false)
                return false; //neni vsechno vyplneno
            }
        }else if(step == 3){
            if(!insurance.electronic_comm){
                warn('Souhlaste se zpracováním osobních údajů.')
                setLoading(false)
                return false; //neni vsechno vyplneno
            }
            if(!(owner.firstname && owner.surname && owner.birth_num && owner.email && owner.phone)){
                warn('Prosím vyplňte všechny osobní údaje!')
                setLoading(false)
                console.log(owner)
                return false; //neni vsechno vyplneno
            }
            if(!(owner.phone.toString().length >= 9)){
                warn('Prosím vyplňte platné telefonní číslo!')
                setLoading(false)
                return false
            }
            if(owner.birth_num.toString().length !== 10 || parseInt(owner.birth_num) % 11 !== 0){
                warn('Prosím vyplňte platné rodné číslo!')
                setLoading(false)
                return false
            }
            if(!(address.street && address.town && address.post_code && address.house_num)){
                warn('Prosím vyplňte vaší trvalou adresu!')
                setLoading(false)
                return false; //neni vsechno vyplneno
            }
            if(!(doggo.chip_num)){
                warn('Prosím vyplňte číslo čipu!')
                setLoading(false)
                return false; //neni vsechno vyplneno
            }
            if (doggo.chip_num.length !== 15) {
                warn('Číslo čipu musí být dlouhé 15 znaků!')
                setLoading(false)
                return false;
            }
            if(contactAddress.different){
                if(!(contactAddress.street && contactAddress.town && contactAddress.post_code && contactAddress.house_num)){
                    warn('Prosím vyplňte vaší kontaktní adresu!')
                    setLoading(false)
                    return false; //neni vsechno vyplneno
                }
            }
            if(breedingAddress.different){
                if(!(breedingAddress.street && breedingAddress.town && breedingAddress.post_code && breedingAddress.house_num)){
                    warn('Prosím vyplňte adresu chovu!')
                    setLoading(false)
                    return false; //neni vsechno vyplneno
                }
            }
            if(breedingAddress.different){
                if(!(breedingAddress.street && breedingAddress.town && breedingAddress.post_code && breedingAddress.house_num)){
                    warn('Prosím vyplňte adresu chovu!')
                    setLoading(false)
                    return false; //neni vsechno vyplneno
                }
            }
            if(breedingAddress.different){
                if(breedingAddress.post_code.replace(/\s/g, '').length > 5){
                    warn('Neplatný formát PSČ')
                    setLoading(false)
                    return false; //neni vsechno vyplneno
                }
            }
            if(contactAddress.different){
                if(contactAddress.post_code.replace(/\s/g, '').length > 5){
                    warn('Neplatný formát PSČ')
                    setLoading(false)
                    return false; //neni vsechno vyplneno
                }
            }
            if(address.post_code.replace(/\s/g, '').length > 5){
                warn('Neplatný formát PSČ')
                setLoading(false)
                return false; //neni vsechno vyplneno
            }

        }
        return true;
    }

    let handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        if(!validateInputs()){
            return
        }

        try {
            if(step == 1 || step == 3 || step == 4){

                let arrOwner = JSON.parse(JSON.stringify(owner))
                arrOwner["address"] = JSON.parse(JSON.stringify(address))
                arrOwner["contact_address"] = JSON.parse(JSON.stringify(address))
                arrOwner["breeding_address"] = JSON.parse(JSON.stringify(address))
                if(contactAddress.different == true){
                    arrOwner["contact_address"] = JSON.parse(JSON.stringify(contactAddress))
                }
                if(breedingAddress.different == true){
                    arrOwner["breeding_address"] = JSON.parse(JSON.stringify(breedingAddress))
                }
                arrOwner["address"]["post_code"] = arrOwner["address"]["post_code"].replace(/\s/g, '')
                arrOwner["contact_address"]["post_code"] = arrOwner["contact_address"]["post_code"].replace(/\s/g, '')
                arrOwner["breeding_address"]["post_code"] = arrOwner["breeding_address"]["post_code"].replace(/\s/g, '')

                //split the address house number for all addresses
                let house_num = arrOwner["address"]["house_num"]
                if(house_num.indexOf("/") !== false){
                    arrOwner["address"]["orient_num"] = house_num.split('/')[1]
                    arrOwner["address"]["house_num"] = house_num.split('/')[0]
                }

                house_num = arrOwner["contact_address"]["house_num"]
                if(house_num.indexOf("/") !== false){
                    arrOwner["contact_address"]["orient_num"] = house_num.split('/')[1]
                    arrOwner["contact_address"]["house_num"] = house_num.split('/')[0]
                }

                house_num = arrOwner["breeding_address"]["house_num"]
                if(house_num.indexOf("/") !== false){
                    arrOwner["breeding_address"]["orient_num"] = house_num.split('/')[1]
                    arrOwner["breeding_address"]["house_num"] = house_num.split('/')[0]
                }

                arrOwner["phone"] = arrOwner["phone"].replace(/\s/g, '')

                let url = getUrl();//get production or development url
                if(step == 4){
                    url = url+"?method=contract"
                }else if(step == 1){
                    arrOwner["firstname"] = "Kalkulace"
                    arrOwner["surname"] = "Kalkulace"
                    arrOwner["birth_num"] = "0102030401"
                    arrOwner["chip_num"] = "1111111"
                }
                let arrDoggo = JSON.parse(JSON.stringify(doggo))
                arrDoggo["racing_dog"] = doggo.racing_dog.toString()
                arrDoggo["working_dog"] = doggo.working_dog.toString()
                let arrInsuance = JSON.parse(JSON.stringify(insurance))
                let arr = {
                    dog: arrDoggo,
                    owner: arrOwner,
                    insurance:arrInsuance
                }
                let res = await fetch(url, {
                    method: "POST",
                    body: JSON.stringify(arr),
                    mode: 'cors'
                 })
                let resJson = await res.json();
                setResponse({ ...response, [step]: resJson });
                console.log(resJson)
                if(step == 4){
                    if(resJson.insurance.CPP[""].payment == 0){
                        errorMesssage("Prosím zkontrolujte všechny parametry")
                        setLoading(false)
                        return;
                    }
                }else{
                    if(resJson.insurance.message){
                        console.log(resJson.insurance.message)
                        error(resJson.insurance.message)
                        setLoading(false)
                        return;
                    }
                    if(resJson.insurance.payment == 0){
                        errorMesssage("Prosím zkontrolujte všechny parametry")
                        setLoading(false)
                        return;
                    }
                }


            }
            setStep(step+1)
            setLoading(false)
            console.log(response)
        } catch (err) {
          console.log(err);
          setLoading(false)
        }
      };

    //workaround -> to send the event only once per session
    const [sentEvents, setSentEvents] = useState([]);
    function sendEvent(name){
        console.log(sentEvents);
        if(sentEvents.includes(name) === false){
            try{
                window.dataLayer = window.dataLayer || [];  
                var gtag=function gtag(){window.dataLayer.push(arguments)};
                gtag('event', name)
                console.log('event', name)
                setSentEvents(prevState => {return [...prevState, name]})
            }
            finally {
                ;
            }
        }
    }

    switch (step){
        case 1:
            content =  <HafanPageOne
                            doggo={doggo}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            setStep={setStep}
                            getValue={getValue}
                            setModalProps={setModalProps}
            />;
            sendEvent('landingPage')
            break;
        case 2:
            content = <HafanPageTwo
                            price={response[1]["insurance"]["payment"]}
                            doggo={doggo}
                            insurance={insurance}
                            handleSubmit={handleSubmit}
                            setStep={setStep}
                            setModalProps={setModalProps}
                />; //show calculated results
            sendEvent('calculation')
            break;
        case 3:
            content = <HafanPageThree
                            owner={owner}
                            address={address}
                            contactAddress={contactAddress}
                            breedingAddress={breedingAddress}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            setStep={setStep}
                            getValue={getValue}
                            setModalProps={setModalProps}
            />;
            sendEvent('additionalInfo')
            break;
        case 4:
            content = <HafanPageFour
                            response={response[3]}
                            doggo={doggo}
                            insurance={insurance}
                            handleSubmit={handleSubmit}
                            setStep={setStep}
                            setModalProps={setModalProps}
            />; //show calculated results
            sendEvent('finalPrice')
            break;
        case 5:
            content = <HafanPageFive
                            response={response[4]} amount={response[4]["insurance"]["CPP"][""]["payment"]}
                            cntr_number={response[4]["insurance"]["CPP"][""]["contract_id"]}/>; //show calculated results
            sendEvent('contract')
            break;

        default:
            content = <div>Loading</div>;
    }



    return(
        <HafanInsurance step={step}>
                {content}
            <Loader loading={loading}/>
            <ToastContainer />
            <HelperModal  isShown={modalProps.visible} content={modalProps.content} setModalProps={setModalProps}/>
        </HafanInsurance>
    )



}
export default Hafan
