import {isProd} from "./getEnv";


export const getUrl = () =>{

    return process.env["REACT_APP_API_URL"];

    // if(isProd()){
    //     return 'https://online.hafanplus.cz/api/ws_server_dog.php'
    // }
    // else{
    //     return 'https://cpp-hafan-test.feds.pro/api/ws_server_dog.php'
    // }
}
