import "../../css/inlineRatio.scss"


const RatioSelectItem = ({
    wholeName,
    name,
    setSelected,
    isSelected,
    icon
                         }) => {


    return (
        <div className="inlineRadio col-sm-4" onClick={() => setSelected(name)}>
            <div className={`imgWrapper ${isSelected ? "active": undefined}`}>
                    {isSelected ?  <i className="fas fa-check"/>  : "" }

                    {/*<img src="" className="tooltipIcon" data-toggle="modal"*/}
                    {/*     data-target="#helpCilEvropa"/>*/}

                    <div style={{clear: "both"}}/>
                    <div  className={isSelected ? "active": undefined}>
                        <div className="row">
                            <div className="col-xs-5 col-sm-12 text-center">
                                <i className={`${icon}  ${isSelected ? " not-checked": ""} `}/>
                            </div>
                            <div className="col-xs-7 col-sm-12">
                                <div className="radioTitle minh50resp">{wholeName}</div>
                            </div>
                        </div>

                    </div>
            </div>
        </div>
    )

}
export default RatioSelectItem
