/**
 * This one works as inline element
 */
import {useState} from "react";
import RatioSelectItem from "../../Components/Inputs/RatioSelectItem";
import icon from "../../images/tooltipIcon.png";

const RadioSelect = ({
                         wholeName,
                         name,
                         type,
                         getValue,
                         handler,
                         placeholder,
                         errorMessage,
                         items,
                         helper,
                         modal
                     }) => {



    const [selected, setSelected] = useState(null)

    function onChange(value){
        setSelected(value);
        handler({target:
            {value: value,
            getAttribute: function(val){
                if(val == "data-name"){
                    return name;
                }
            }}}
        )
    }


    return(
        <div className="col-md-12">
            <div className="form-group row">
                <label className="col-md-4" htmlFor="">{wholeName}
                    {helper? <img src={icon} className="tooltipIcon inline" onClick={(e) => {
                        e.preventDefault();
                        modal({visible: true, content: helper})
                    }}/>: ""}
                 </label>
                <div className="col-md-8">
                    {items.map((item) =>
                        <RatioSelectItem
                            key={item.name}
                            wholeName={item.wholeName}
                            setSelected={onChange}
                            name={item.name}
                            icon={item.icon}
                            isSelected={getValue(name) === item.name ||
                                selected === item.name}/>
                    )}
                </div>
                {/*<div className="col-md-4">*/}
                {/*    {errorMessage && <span className="block validator-msg">{errorMessage}</span> }*/}
                {/*</div>*/}
            </div>
        </div>




    )
}
export default RadioSelect
