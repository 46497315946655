import { toast } from 'react-toastify';

export const sexShort = (sex) =>{
    return (sex === "PES"? "M" : "F")
}

export function warn(message){
    toast.warn(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}
export function error(message){
    toast.error(message.substring(message.indexOf("- ") + 2) + "/n Zavolejte nám, Vašeho pejska společně pojistíme po telefonu za pár minut:  957 444 444 (v pracovní dny od 8:00 do 18:00 hod)", {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}
export function errorMesssage(message){
    toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}