import Recap from "../Components/Recap";

const HafanPageTwo = (props) =>{

    return(

        <div>
            <form>
                <Recap
                    dog={props.doggo}
                    insurance={props.insurance}
                />

                <div className="formBox graybg">
                    <div className="formBoxContent summary">
                        <div className={"row"}>
                            <div className={"col-xs-8"}>
                                <h3>Vaše cena</h3>
                            </div>
                            <div className="col-xs-4 cena">
                                <h3>
                                    <span>{props.price}</span>
                                    <span className="kc"> Kč</span>
                                </h3>
                            </div>
                        </div>
                        {/*<h2>Vaše cena</h2>*/}
                        {/*<div className="row">*/}
                        {/*    <div className="col-xs-8 tit">*/}
                        {/*        Balíček*/}
                        {/*    </div>*/}
                        {/*    <div className="col-xs-4 cena">*/}
                        {/*        <span>226</span>*/}
                        {/*        <span className="kc"> Kč</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="row">*/}
                        {/*    <div className="col-xs-8 tit">*/}
                        {/*        Připojištění*/}
                        {/*    </div>*/}
                        {/*    <div className="col-xs-4 cena">*/}
                        {/*        <span>0</span><span*/}
                        {/*        className="kc"> Kč</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="row">*/}
                        {/*    <div className="col-xs-8 tit">*/}
                        {/*        Sleva*/}
                        {/*    </div>*/}
                        {/*    <div className="col-xs-4 cena">*/}
                        {/*        <span>-23</span>*/}
                        {/*        <span className="kc"> Kč</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="row celk">*/}
                        {/*    <div className="col-xs-8">*/}
                        {/*        <p className="tit">*/}
                        {/*            Celková cena po slevě</p>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-xs-4 cena">*/}
                        {/*        <span>203</span><span*/}
                        {/*        className="kc"> Kč</span>*/}
                        {/*        <a style="display:none" className="">*/}
                        {/*            <p className="dwnldlink1"><i className="fa fa-download" aria-hidden="true"/>Stáhnout*/}
                        {/*                kalkulaci v PDF</p>*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                </div>



                <div className="row stepButtons">
                    <div className="col-lg-6  col-sm-12">
                        <button type="button" className="btn btn-secondary backButton" onClick={() => props.setStep(1)}>
                            Zpět
                        </button>
                    </div>
                    <div className="col-lg-6 col-sm-12  text-right">
                        <button className={"btn btn-primary continueButton"} onClick={props.handleSubmit}>
                            Chci sjednat
                        </button>
                    </div>
                </div>

            </form>
        </div>

    )
}

export default HafanPageTwo;
