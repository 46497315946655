import DatePicker from "antd/lib/date-picker";
import ConfigProvider from "antd/lib/config-provider";
import 'antd/dist/antd.css';
import locale from 'antd/lib/locale/cs_CZ';
import "../../css/inputs.scss"
import icon from "../../images/tooltipIcon.png"
import moment from "moment";

export default function  InputDatePicker({
    wholeName,
    name,
    type,
    getValue,
    handler,
    placeholder,
    errorMessage,
    defaultValue,
    disabled,
    helper,
    modal


                                         })
{

    function onChange(date, dateString) {
        date = date.format(dateString)
        let object = {target:
            {value: date,
            getAttribute: function(val){
                if(val == "data-name"){
                    return name;
                }
            }}}
        handler(object)
    }

    const dateFormat = 'DD.MM.YYYY';

    return(
        <div className="col-md-12">
            <div className="form-group row">
                <label className="col-md-4" htmlFor="">{wholeName}
                    {helper? <img src={icon} className="tooltipIcon inline" onClick={(e) => {
                            e.preventDefault();
                        modal({visible: true, content: helper})
                    }}/>: ""}
                </label>
                <div className="col-md-4">
                    <ConfigProvider locale={locale}>
                       <DatePicker
                           onChange={onChange}
                           format={dateFormat}
                           className={"datePickerInput"}
                           // value={getValue(name)}
                           defaultValue={!!getValue(name) ?  moment(getValue(name), dateFormat) : (defaultValue && moment(defaultValue, dateFormat))}

                           disabled={disabled}
                       />
                    </ConfigProvider>
                </div>
                <div className="col-md-4 lbl hidden-xs hidden-sm">
                    {/* {helper? <img src="/images/tooltipIcon.png" className="tooltipIcon" onClick={(e) => {e.preventDefault();modal({visible: true, content:helper})}}></img> : ""} */}
                    {errorMessage && <span className="block validator-msg">{errorMessage}</span> }
                </div>
            </div>
        </div>

        )

}
